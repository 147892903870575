<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>财务中心</a-breadcrumb-item>
      <a-breadcrumb-item>课消记录</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="2">
          <span slot="tab">课消记录</span>
          <course v-if="activeKey === '2'" />
        </a-tab-pane>
        <a-tab-pane key="1">
          <span slot="tab">学员课消</span>
          <record v-if="activeKey === '1'" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import record from './record'
import course from './course'

export default {
  name: 'costtimes',
  components: {
    record,
    course
  },
  data() {
    return {
      activeKey: '2'
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>
