<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
          <!-- <a-form layout='inline'>
            <a-form-item>
                <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
          </a-form> -->
      </div>
      <div class="search">
          <a-form layout='inline'>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>时间筛选</span>
                </template>
                <a-range-picker
                    :ranges="rangesData"
                    :value='rangesValue'
                    :allowClear="true"
                    :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]" 
                    style="width:240px"
                    @change="(date, dateString)=>onChange(date, dateString,2)" />
                </a-tooltip>
            </a-form-item>

            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>所属校区</span>
                </template>
                    <a-select v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' showSearch allowClear placeholder="请选择校区" :filterOption="filterOption" style="width:210px">
                        <a-select-option v-for="(item, index) of studios" :key="index" :value="item.studio_id">{{ item.filter_name }}</a-select-option>
                    </a-select>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
                <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="课程科目" >
                        <a-select allowClear v-model="searchParams.search.course_cate_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of categorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="授课老师" >
                        <a-select allowClear v-model="searchParams.search.teacher_id" showSearch @search="handleSearch" :filterOption="filterOption" placeholder="请选择">
                          <a-select-option v-for="(d, index) of teachers" :key="index" :value="d['teacher_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="所属班级" >
                        <a-select allowClear v-model="searchParams.search.class_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of classes" :key="index" :value="d['class_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="course_class_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1500 }">
        <template slot="index" slot-scope="text, record , index">
          <span v-if="index+1 == list.length">合计</span>
          <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="classDate" slot-scope="text, record, index">
          <a v-if="index+1 == list.length"></a>
          <a v-else @click="showStudentsModal(record)" style="display:flex">
            <div style="margin-right:5px;line-height:19px">{{ moment(record.class_start).format('YYYY-MM-DD【dddd】')}}{{record.class_time}}</div>
            <div v-if="record.is_demo">
              <img style="width:18px" src="@/assets/demo.png">
            </div>
          </a>
        </template>

        <template slot="reserve_count" slot-scope="text, record, index">
          <span v-if="index+1 == list.length">{{ record.reserve_count }}</span>
          <a v-else @click="showStudentsModal(record)">{{ record.reserve_count }}</a>
        </template>

        <template slot="signin_count" slot-scope="text, record, index">
          <span v-if="index+1 == list.length">{{ record.signin_count }}</span>
          <a v-else @click="showStudentsModal(record)">{{ record.signin_count }}</a>
        </template>

        <template slot="cancel_count" slot-scope="text, record, index">
          <span v-if="index+1 == list.length">{{ record.cancel_count }}</span>
          <a v-else @click="showStudentsModal(record)">{{ record.cancel_count }}</a>
        </template>

        <template slot="action" slot-scope="text,record">
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item @click="showStudentsModal(record)" key="2">
                学员签到
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>

      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
    </div>
    <studentsModal v-if="studentsVisible" :item="modalData"/>
  </div>
</template>

<script>
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'
import studentsModal from '@/views/classes/bjgl/detail/bjpk/studentModal'

const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '上课时间', width:'260px', dataIndex: 'class_date', key: '1', scopedSlots: { customRender: 'classDate' } , fixed: 'left' ,sorter:()=>{}},
  { title: '上课校区', dataIndex: 'studio_id', key: 'studio_id' ,sorter:()=>{},ellipsis:true},
  { title: '班级名称', dataIndex: 'class_id', key: 'class_id' ,sorter:()=>{},ellipsis:true},
  { title: '授课老师', dataIndex: 'teacher_id', key: '2' ,sorter:()=>{}},
  { title: '上课教室', dataIndex: 'room_id', key: 'room_id',sorter:()=>{},ellipsis:true},
  { title: '课程名称', width: '160px', dataIndex: 'course_id',sorter:()=>{},ellipsis:true},
  { title: '排课', dataIndex: 'reserve_count',width:'80px', key: '4', scopedSlots: { customRender: 'reserve_count' } ,align:"center"},
  { title: '到课', dataIndex: 'signin_count',width:'80px', key: '5', scopedSlots: { customRender: 'signin_count' } ,align:"center"},
  { title: '请假', dataIndex: 'cancel_count',width:'80px',key: 'cancel_count', scopedSlots: { customRender: 'cancel_count' } ,align:"center"},
  { title: '单课消耗', dataIndex: 'class_costs',width:'80px', key: '6' ,align:"center"},
  { title: '全员课消', dataIndex: 'total_class_costs',width:'80px',  key: 'total_class_costs' ,align:"center"},
  { title: '课消金额', dataIndex: 'total_class_costs_price',width:'110px',  key: 'total_class_costs_price' ,align:"center"},
  { title: '课程状态', dataIndex: 'class_status', key: '9' ,align:"center",sorter:()=>{}},
  { title: '排课人员', dataIndex: 'created_by', key: 'created_by' ,align:"center"},
//   { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]
    export default {
        name:'listMode',
        data() {
            return {
                loading: false,
                visible: false,
                exportLoading: false,
                studentsVisible: false,
                columns,
                list:[],
                studios: [],
                teachers: [],
                classes: [],
                categorys: [],
                rooms: [],
                paramsData:{
                    start_date:moment().subtract(1,'months').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
            }
        },
        mixins: [ tableMixins , ranges ],
        provide() {
            return {
            parent: this
            }
        },
        components: {
          studentsModal,
        },
        computed: {
            realname(){
                return this.$store.getters.realname
            }
        },
        created () {
            this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
            this.getRoom()
            this.getStudioList()
            this.getTeacher()
            this.getClasses()
            this.getCategory()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList() {
                if(this.$route.query.start_date){
                  this.rangesValue = [moment(this.$route.query.start_date),moment(this.$route.query.end_date)]
                  this.paramsData.start_date = this.$route.query.start_date
                  this.paramsData.end_date = this.$route.query.end_date
                }
                this.loading = true
                this.searchParams.search.start_date = this.paramsData.start_date || moment().subtract(1,'months').format('YYYY-MM-DD')
                this.searchParams.search.end_date = this.paramsData.end_date || moment().format('YYYY-MM-DD')
                let res = await this.$store.dispatch('financeCosttimesCourseAction', {data:this.searchParams})
                this.list = res.items
                this.list.push({
                  course_class_id:-111,
                  class_date:'',
                  studio_id:'',
                  class_id:'',
                  teacher_id:'',
                  room_id:'',
                  course_id:'',
                  reserve_count:res._total.reserve_count,
                  signin_count:res._total.signin_count,
                  cancel_count:res._total.cancel_count,
                  class_costs:'',
                  total_class_costs:res._total.total_class_costs,
                  total_class_costs_price:res._total.total_class_costs_price,
                  class_status:'',
                  created_by:''
                })
                this.pageParams = res._meta
                this.loading = false
            },
            async getStudioList() {
                let res = await this.$store.dispatch('searchBelongStudioAction', { })
                this.studios = res.data
            },
            async getRoom() {
                let res = await this.$store.dispatch('searchStudioRoomAction', {})
                this.rooms = res.data
            },
            async getTeacher(val) {
              let res = await this.$store.dispatch('searchTeacherAction', val)
              this.teachers = res.data
            },
            async getClasses() {
              let res = await this.$store.dispatch('searchClassesAction', { })
              this.classes = res.data
            },
            async getCategory() {
              let res = await this.$store.dispatch('searchCourseTypeAction', {})
              this.categorys = res.data
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {search:this.searchParams.search,sort:this.searchParams.sort}
                let res = await this.$store.dispatch('schedulecourseExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `我的课表.${moment().format('YYYY.MM.DD')}.xls`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
            showStudentsModal(item) {
              this.modalData = item
              this.studentsVisible = true
            },
            hideStudentModal(type) { 
              this.studentsVisible = false
            },
            handleSearch(e){
                let obj = {"q":e}
                this.getTeacher({params:obj})
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>