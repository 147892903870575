<template>
  <div>
    <div>
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canExcelexport">
              <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <template slot="title">
                    <span>时间筛选</span>
                </template>
                <a-range-picker 
                  :ranges="rangesData" 
                  :disabled-date="disabledDate" 
                  allowClear 
                  v-model="searchData.class_date" 
                  :placeholder="['开始时间', '结束时间']" 
                  style="width:240px"/>
                </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>学员名称/手机号</span>
                </template>
                <a-input allowClear v-model.trim="searchData.fuzzy" placeholder="学员名称/手机号" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-select style="width: 240px" allowClear v-model="searchData.studio_id" mode="multiple" :filterOption="filterOption" :maxTagCount="1" :maxTagTextLength='5' placeholder="请选择上课校区">
                <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="课程科目" >
                        <a-select allowClear v-model="searchData.course_cate_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of categorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="授课老师" >
                        <a-select allowClear v-model="searchData.teacher_id" showSearch @search="handleSearch" :filterOption="filterOption" placeholder="请选择">
                          <a-select-option v-for="(d, index) of teachers" :key="index" :value="d['teacher_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                      <a-form-item class="block-line" label="所属班级" >
                        <a-select allowClear v-model="searchData.class_id" placeholder="请选择">
                          <a-select-option v-for="(d, index) of classes" :key="index" :value="d['class_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="book_id"
          :columns="columns" :dataSource="list" :scroll="{ x: 1700 }" @change="handleChange">
          <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="studentName" slot-scope="text, record, index">
            <span v-if="index+1 == list.length"></span>
            <LUser v-else :data="record"/>
          </template>
          <template slot="course_class_date" slot-scope="text, record, index">
            <span v-if="index+1 == list.length"></span>
            <div v-else style="margin-right:5px;line-height:19px">{{ moment(record.course_class_date).format('YYYY-MM-DD【dddd】')}}{{record.course_class_time}}
              <span v-if="record.is_demo">
                <img style="width:18px" src="@/assets/demo.png">
              </span>
            </div>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width:200, dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '上课校区', dataIndex: 'studio_id', key: 'studio_id',sorter:()=>{},ellipsis:true},
  { title: '班级名称', dataIndex: 'class_id', key: 'class_id',sorter:()=>{},ellipsis:true},  
  { title: '授课老师', dataIndex: 'teacher_id', key: 'teacher_id' ,sorter:()=>{}},
  { title: '上课日期', width:260, dataIndex: 'course_class_date', key: 'course_class_date', scopedSlots: { customRender: 'course_class_date' }  ,sorter:()=>{}},
  // { title: '课程时间', dataIndex: 'course_class_time', key: '9' ,sorter:()=>{}},
  { title: '课程名称', dataIndex: 'course_name', key: 'course_name' ,align:"left",ellipsis:true,sorter:()=>{}},
  { title: '上课教室', dataIndex: 'room_id', key: 'room_id' ,align:"center",sorter:()=>{}},
  { title: '课时消耗', dataIndex: 'book_costs', key: 'cost_times' ,align:"center",sorter:()=>{}},
  { title: '课消金额', dataIndex: 'book_balance', key: 'class_balance',align:"center",sorter:()=>{}},
  { title: '签到员工', dataIndex: 'checked_by', key: 'checked_by' ,ellipsis:true,align:"right"},
  { title: '签到时间', dataIndex: 'checked_at', key: 'checked_at' ,ellipsis:true,align:"center"},
  { title: '备注', dataIndex: 'book_remark', key: 'book_remark' ,ellipsis:true},
]
import C_ITEMS from '@/utils/items'	
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
import moment from 'moment'
export default {
  name: 'record',
  rovide() {
    return {
      parent: this
    }
  },

  components: {
    // refundModal
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      paymentStatus: C_ITEMS.paymentStatus,
      studios: [],
      teachers: [],
      classes: [],
      categorys: [],

      searchData: {
        class_date:[moment().subtract(1,'months'),moment()]
      },
      exportLoading:false,
      visible: false,
      refundVisible: false,
      authType:['finance','cost-times'],
    }
  },

  created() {
    this.getStudio()
    this.getTeacher()
    this.getClasses()
    this.getCategory()
  },
  mixins: [ ranges , authority, tableMixins ],
  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchStudioAction', {})
      this.studios = res.data
    },
    async getTeacher(val) {
      let res = await this.$store.dispatch('searchTeacherAction', val)
      this.teachers = res.data
    },
    async getClasses() {
      let res = await this.$store.dispatch('searchClassesAction', { })
      this.classes = res.data
    },
    async getCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.categorys = res.data
    },
    async getList(isExport) {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let params = { data: this.searchParams }
      if (isExport) {
        params.data.export = 1
        params.responseType = 'blob'
      }
      let res = await this.$store.dispatch('financeCosttimesAction', params)
      if (!isExport) {
        this.list = res.items
        this.list.push({
          book_id:-111,
          student_name:'',
          studio_id:'',
          class_id:'',
          teacher_id:'',
          course_class_date:'',
          course_name:'',
          room_id:'',
          book_costs:res._total.book_costs,
          book_balance:res._total.book_balance,
        })
        this.pageParams = res._meta
      }
      this.loading = false
    },
    async exportData() {
      this.getList(true)
    },

    showRefundModal(item) {
      this.modalData = item
      this.refundVisible = true
    },
    hideRefundModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.refundVisible = false
    },
    handleChange(pagination, filters, sorter){
        if(sorter.order){
        if(sorter.order === "ascend"){
            this.searchParams['sort'] = `${sorter.sorter.columnKey}`
        }else{
            this.searchParams['sort'] = `-${sorter.sorter.columnKey}`
        }
        }else{
        this.searchParams['sort'] = ''
        }
        this.getList();
    },
    handleSearch(e){
      let obj = {"q":e}
      this.getTeacher({params:obj})
    },
    async toExport(){
      this.exportLoading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'class_date') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      let exportData = {
          search:obj,
          sort:this.searchParams.sort
      }
      let res = await this.$store.dispatch('financeCosttimesExportAction', exportData)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `课消记录.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>
